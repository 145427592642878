import React       from 'react';
import { graphql } from 'gatsby';
import Img         from 'gatsby-image';

import Wrapper       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import HeaderMedia   from '@interness/theme-default/src/components/HeaderMedia';
import Heading       from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Separator     from '@interness/web-core/src/components/structure/Separator/Separator';
import { findMedia } from '@interness/web-core/src/components/_helpers';

import { H3 } from '../components/styles';

function GlasseidenbaenderPage(props) {
  const pdf = findMedia(props.data.pdf.media, '1-glasband');
  const muster = findMedia(props.data.images.media, 'glas');
  console.log(muster)
  return (
    <>
      <HeaderMedia id={'glasseidebaender'}/>
      <Wrapper>
        <Spacer/>
        <Heading>Glasseidenband LW</Heading>
        <Spacer height={20}/>
        <p>
          Unser Glasseidenband besteht aus gezogenen Fasern die aus alkalifreiem Spezialglas gezogenen werden. Sie sind
          nicht brennbar.
          Weitere Vorteile sind eine gute Verrottungsbeständigkeit, eine geringe Feuchteaufnahme, eine geringe Dehnung.
        </p>
        <p>
          Unsere Glasseidenbänder werden im Elektromotoren- und Transformatorenbau zum Abbinden und Isolieren
          eingesetzt. Unsere Bänder haben eine hohe Chemikalien- und UV-beständig, sind hitzebeständig und weitgehend
          unempfindlich gegen Oxydationseinflüsse, Säure und Bakterien.
        </p>
        <Separator/>
        <H3>Muster</H3>
        <div style={{ textAlign: 'center' }}>
          <Img fixed={muster.childImageSharp.fixed} alt="glasband"/>
        </div>
        <Separator/>
        <H3>Spezifikation</H3>
        <table>
          <tbody>
          <tr>
            <td><b>Glaseidenband</b></td>
            <td>0,14 mm dick</td>
          </tr>
          <tr>
            <td><b>Bezeichnung</b></td>
            <td>Glasseidenband</td>
          </tr>
          <tr>
            <td><b>Material</b></td>
            <td>E - Glas</td>
          </tr>
          <tr>
            <td><b>Gewebebindung</b></td>
            <td>LW - Bindung</td>
          </tr>
          <tr>
            <td><b>Gewebestellung</b></td>
            <td>Kette : 20 Fd Tex 34/1 per cm,
              Schuß : 10 Fd Tex 34/1 per cm
            </td>
          </tr>
          <tr>
            <td><b>Gewicht</b></td>
            <td>144 g / 100m / per cm</td>
          </tr>
          <tr>
            <td><b>Reißfestigkeit</b></td>
            <td>280 N per cm</td>
          </tr>
          <tr>
            <td><b>Reißdehnung</b></td>
            <td>ca. 3%</td>
          </tr>
          <tr>
            <td><b>Erweichungstemperatur</b></td>
            <td>840°</td>
          </tr>
          <tr>
            <td><b>Schlichte auf Kette</b></td>
            <td>0,6-0,8% Silan -Basis</td>
          </tr>
          <tr>
            <td><b>Schlichte auf Schuß</b></td>
            <td>0,7 - 1,25% auf Stärke -Fett -Basis</td>
          </tr>
          </tbody>
        </table>
        <a href={pdf.publicURL} target="_blank">Download Datenblatt (PDF)</a>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default GlasseidenbaenderPage;

export const query = graphql`
    query {
        pdf: directusMediaCollection(name: {eq: "pdf"}) {
            media {
                file {
                    localFile {
                        name
                        publicURL
                    }
                }
            }
        }
        images: directusMediaCollection(name: {eq: "muster"}) {
            media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fixed {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
            }
        }
    }
`;